// istanbul ignore file
import { translate, translateFormatter } from '@manomano-internal/i18n/esm/placeholders';
export const arrows = translate({
  "titleLeft": "Bisherige",
  "titleRight": "N\xE4chster"
});
export const price = translate({
  "vatExclLabel": "EXKL.MWST.",
  "vatInclLabel": "INKL.MWST."
});
export const rating = {
  ariaLabel: (value: number) => translateFormatter<{
    value: number;
  }>("{value} von 5", undefined, undefined, "de").format({
    value
  }) as string,
  reviewsLabel: (value: number) => translateFormatter<{
    value: number;
  }>("bei {value, plural, =1 {# Kundenbewertung} other {# Kundenbewertungen}}", undefined, undefined, "de").format({
    value
  }) as string
};
export const pagination = translate({
  "previousPageAriaLabel": "Vorherige Seite",
  "nextPageAriaLabel": "N\xE4chste Seite"
});
export const counter = translate({
  "decrementAriaLabel": "Verringern",
  "incrementAriaLabel": "Erh\xF6hen"
});
export const inputPhone = {
  countriesLabels: translate({
    "BE": "Belgien",
    "DE": "Deutschland",
    "ES": "Spanien",
    "FR": "Frankreich",
    "GB": "Gro\xDFbritannien",
    "IT": "Italien",
    "LU": "Luxemburg",
    "CH": "Schweiz"
  })
};